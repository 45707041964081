import { graphql } from 'gatsby';
import { Location } from 'history';
import React from 'react';
import BeforeYouStartBaseComponent, {
  BeforeYouStartData,
} from 'components/FormPage/beforeYouStart';
import { DriverType } from 'types/forms';

type BeforeYouStartProps = {
  data: BeforeYouStartData;
  location: Location;
};

const BeforeYouStartPage: React.FC<BeforeYouStartProps> = ({ data, location }) => (
  <BeforeYouStartBaseComponent data={data} location={location} driverType={DriverType.TEMPORARY} />
);

export default BeforeYouStartPage;

export const query = graphql`
  query {
    csAddDriverBeforeYouStartTemplate(driver_type: { eq: "Temporary" }) {
      meta_title
      heading
      rich_text
      next_button {
        display_text
        screen_reader_text
      }
    }
    csAccountAreaPage(page_id: { eq: "account-area-policy" }) {
      url
    }
  }
`;
